











import { Component } from 'vue-property-decorator'
import { AbstractModal } from '../../../shared/organisms/AbstractModal'
import { Loader } from '../../../shared/molecules/Loader'
import { BlikProcessingPayload } from './BlikProcessingModal'
import { Inject } from '../../../../support/plugins'
import { CheckoutServiceType, ICheckoutService } from '../../services/checkout/contracts'

@Component<BlikProcessingModal>({
  name: 'BlikProcessingModal',
  components: { Loader },
  created () {
    this.interval = setInterval(() => {
      this.verifyStatus()
    }, this.intervalTimeout)

    setTimeout(() => {
      clearInterval(this.interval)
      this.payload.onFail(
        new Error(
          String(this.$t('front.checkout.organisms.BlikWhitelabel.timeout'))
        )
      )
    }, this.verifyTimeout)
  },
  destroyed () {
    clearInterval(this.interval)
  }
})
export class BlikProcessingModal extends AbstractModal<BlikProcessingPayload> {
  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  protected interval?: any = undefined
  protected intervalTimeout: number = 3 * 1000 // 3 seconds
  protected verifyTimeout: number = 1.5 * 60 * 1000 // 1.5 minute

  public async getOrderStatus (): Promise<string | null> {
    try {
      const orderStatus = await this.checkoutService.getOrderStatus(this.payload.orderNumber)

      return orderStatus.status
    } catch (e) {
    }

    return null
  }

  public async verifyStatus (): Promise<void> {
    try {
      const orderStatus = await this.getOrderStatus()

      if (orderStatus === 'processing') {
        this.payload.onSuccess()
      }

      if (orderStatus === 'canceled') {
        this.payload.onFail(
          new Error(
            String(this.$t('front.checkout.organisms.BlikWhitelabel.canceled'))
          )
        )
      }
    } catch (e) {
    }
  }
}

export default BlikProcessingModal
